import React from 'react'
import Layout from '../components/layout'
import CustomerServiceRepresentative from '../components/categories/customer-service-representative'
import Seo from '../components/seo'

const CustomerServiceRepresentativePage = ({ location }) => {
  return (
    <Layout location={location}>
      <CustomerServiceRepresentative />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Customer Service Representative Freelancers | Codestaff'
  const description =
    'Hire the best Customer Service Representative freelancers at Codestaff. Get the top 1% of Customer Service Representative professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default CustomerServiceRepresentativePage
