import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Does A Customer Service Representative Do?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A customer service representative is a liaison between your company and customers. A customer service representative answers customer questions, addresses emergency issues and resolves customer complaints."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Why Do I Need A Customer Service Representative?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A customer service representative helps your business develop strong relationships with your customers and clients. If you want to concentrate on growing your business, you need a customer service representative to attend to your customer questions, resolve problems and give suggestions regarding the purchase of additional products and services. This is what codestaff can do for you."
        }
    }
}

const CustomerServiceRepresentative = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Customer Service Representatives.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Customer Service Representatives. Top
                                    companies and start-ups choose Codestaff Customer Service professionals
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Customer Service Representative now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE CUSTOMER SERVICE REPRESENTATIVES' banner='/customer-service-representative.png' bannerAlt='customer service representative banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default CustomerServiceRepresentative